<template>
  <div>
    <div id="logo">
      <img :src="logourl" style="max-width: 300px;max-height: 50%" alt=""/>
    </div>
    <!--<div id="bc">-->
    <!--  <img :src="urlimg" style="width: 100%;height: 100%" alt=""/>-->
    <!--</div>-->

    <!--    头部-->
    <div id="index_div">
      <div style="float: right;margin-right: 50px;display: flex;justify-content: center;margin-top: 2px">
        <div id="font_div">
          <p style="font-size: 12px;font-family: '苹方',serif;font-weight: 300;">{{
              this.nowWeek + '     '
            }}{{ this.nowDate }} {{ this.nowTime }}</p>
        </div>
        <div id="username_div">
          欢迎您 {{ this.GET_DEPART }} {{ username }}
        </div>
        <div style="margin-left: 50px">
          <el-button size="mini" type="gj" @click="loginout">退出平台</el-button>
          <el-button size="mini" type="gj" @click="changepas">修改密码</el-button>
	        <el-button size="mini" type="danger" @click="dialogVisibleOpinion = true">意见反馈</el-button>
        </div>
      </div>
    </div>
    <!--    菜单-->
    <div>
      <div id="mnue_div1">
        <div v-for="(item, i) in menu" :index="i+''" :key="i">
          <div v-if="i === 0" style="margin-top: 50px;margin-left: 20px;display: flex">
            <img :src="item.imgurl" alt="" style="width: 24px;height: 24px;margin-right: 20px"/>
            <span style="font-size: 15px;font-weight: 800">{{ item.name }}</span>
            <img :src="y" style="width: 11px;height: 15px;margin-top: 3px;margin-left: 30px">
          </div>
          <div v-if="i !== 0 && i !== menu.length - 1" style="margin-top: 80px;margin-left: 20px;display: flex">
            <img :src="item.imgurl" alt="" style="width: 24px;height: 24px;margin-right: 20px"/>
            <span style="font-size: 15px;font-weight: 800">{{ item.name }}</span>
            <img :src="y" style="width: 11px;height: 15px;margin-top: 3px;margin-left: 30px">
          </div>
          <div v-if="i !== 0 && i === menu.length - 1" style="margin-top: 80px;margin-left: 20px;margin-bottom: 70px;display: flex">
            <img :src="item.imgurl" alt="" style="width: 24px;height: 24px;margin-right: 20px"/>
            <span style="font-size: 15px;font-weight: 800">{{ item.name }}</span>
            <img :src="y" style="width: 11px;height: 15px;margin-top: 3px;margin-left: 30px">
          </div>
        </div>
      </div>
      <div id="mnue_div2">
        <div v-for="(item, i) in menu" :index="i+''" :key="i" style="display: flex;margin-top: 10px">
          <div v-for="(item1, i1) in item.children" :index="i1+''" :key="i1" style="display: flex;margin-top: 10px">
            <div v-if="i === 0" style="display: flex;margin-top: 10px">
              <div>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item1.name"
                    placement="top-start"
                >
                  <img :src="item1.imgurl" alt="" style="width: 70px;height: 60px;margin-left: 20px"
                       @click="gotourl(item1.frontUrl)"/>
                </el-tooltip>
              </div>
            </div>
            <div v-if="i !== 0 && i !== menu.length - 1" style="display: flex;margin-top: 20px">
              <div>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item1.name"
                    placement="top-start"
                >
                  <img :src="item1.imgurl" alt="" style="width: 70px;height: 60px;margin-left: 20px"
                       @click="gotourl(item1.frontUrl)"/>
                </el-tooltip>
              </div>
            </div>
            <div v-if="i !== 0 && i === menu.length - 1" style="display: flex;margin-top: 20px;margin-bottom: 50px">
              <div>
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item1.name"
                    placement="top-start"
                >
                  <img :src="item1.imgurl" alt="" style="width: 70px;height: 60px;margin-left: 20px"
                       @click="gotourl(item1.frontUrl)"/>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    备案号 -->
    <div style="position: fixed;bottom: 10px;text-align: center;width: 100%">
      <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration:none;color: black;font-size: 16px">
        晋ICP备20002525号-5</a>
    </div>
    <el-dialog v-model="dialogFormVisible" title="修改密码">
      <div style="margin-bottom: 30px">
        <el-input placeholder="请输入原密码" v-model="oldpas" size="small" clearable show-password></el-input>
      </div>
      <div style="margin-bottom: 30px">
        <el-input placeholder="请输入新密码" v-model="newpas1" size="small" clearable show-password></el-input>
      </div>
      <div style="margin-bottom: 30px">
        <el-input placeholder="请确认新密码" v-model="newpas2" size="small" clearable show-password @blur="yztowpas"></el-input>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="qxchange">取消</el-button>
        <el-button v-if="newpasyy" type="primary" @click="changepasok">确认</el-button>
        <el-button v-if="!newpasyy" type="primary" disabled>确认</el-button>
      </span>
      </template>
    </el-dialog>
	<!--  意见反馈-->
	  <div>
		  <el-dialog v-model="dialogVisibleOpinion" title="意见反馈-系统的优化需要大家的意见反馈">
			  <div style="margin-bottom: 2%">
				  <div style="color: red;margin-bottom: 1%">
					  反馈意见并不会收集用户的相关信息，是不记名的^_^
				  </div>
				  <el-image src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/opinion_example.png"/>
			  </div>
			  <div style="margin-bottom: 30px">
				  <el-input placeholder="请输入意见" autosize
				            type="textarea" v-model="opinion_message" size="small" clearable show-password></el-input>
			  </div>
			  <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancelOpinion">取消</el-button>
        <el-button v-if="newpasyy" type="primary" @click="submitOpinion">确认</el-button>
      </span>
			  </template>
		  </el-dialog>
	  </div>
  </div>
</template>
<script>
import {
  ElMessage,
  ElMessageBox
} from "element-plus";

var i = 0;
import {
  mapGetters,
  mapMutations
} from "vuex";
import { ElNotification } from 'element-plus'
import {Encrypt} from "@/utils/secret";
import {setNowTimes} from "@/utils/time";
export default {
  inject: ['reload'],
  name: 'Main',
  data() {
    return {
      timer: null,
      newpasyy: true,
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      menu: [],
      menuchil: [],
      username: '',
      nuuid: '',
      openid: '',
      dangerouslyUseHTMLString: '',
      userid: '',
      dialogFormVisible: false,
      btntxt: '获取验证码',
      oldpas: '',
      newpas1: '',
      newpas2: '',
      urlimg: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/backimg.png',
      logourl: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/logo.png',
      y: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/y.png',
	    opinion_message: '',
	    dialogVisibleOpinion: false
    }
  },
	beforeRouteLeave() {
		document.querySelector('body').setAttribute('style', "margin: 0;padding: 0;height: 100%;width: 100%;background-color: #F2F2F2;")
	},
  computed: {
    ...mapGetters(['GET_MENU']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_DEPART']),
  },
  methods: {
    ...mapMutations(['SET_USER']),
    ...mapMutations(['SET_USERID']),
    ...mapMutations(['SET_BDST']),
    ...mapMutations(['SET_DEPART']),
    ...mapMutations(['SET_POSION']),
    ...mapMutations(['SET_TOKEN']),
    ...mapMutations(['SET_DEPARTFLAG']),
	  ...mapMutations(['SET_TOKEN_CAR']),
	  // 意见反馈
	  cancelOpinion(){
			this.dialogVisibleOpinion  = false
		  this.opinion_message = ''
	  },
	  submitOpinion(){
			this.axios.post('/wxuser/saveOpinion', (response) => {
				if (response.obj){
					ElMessage.success('意见提交成功，会尽快进行优化!')
				}else {
					ElMessage.success('意见提交失败!')
				}
				this.dialogVisibleOpinion  = false
				this.opinion_message = ''
			},{
				opinion_message: this.opinion_message,
				opinion_time: setNowTimes(new Date())
			})
	  },
    loginout() {
      this.SET_TOKEN = '1';
      this.$router.push({
        path: '/'
      })
    },
    setNowTimes() {
      let myDate = new Date()
      // console.log(myDate)
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm = myDate.getMonth() + 1
      let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
      let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
      let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
      let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min
      this.nowWeek = week
    },
    uuid() {
      var s = [];
      var hexDigits = '0123456789abcdef';
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4';
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = '-';

      this.uuidA = s.join('');
      return this.uuidA;
    },
    gotourl(val) {
      sessionStorage.setItem('food_kind_remove_flag', '0')
      let v = val;
      let v2 = v.slice(2);
      let v3 = v.slice(1, 2).toUpperCase();
      let v4 = v3 + v2;
      const time = Date.parse(new Date()) + this.uuid();
      this.$router.push({
        name: v4,
        params: {
          key: time,
        }
      });
    },
    changepas(){
      this.dialogFormVisible = true;
    },
    qxchange(){
      this.dialogFormVisible = false;
      this.oldpas = '';
      this.newpas1 = '';
      this.newpas2 = '';
    },
    yztowpas(){
      if (this.newpas1 !== this.newpas2){
        this.newpasyy = false;
        ElNotification({
          title: '错误',
          message: '两次密码不一致',
          type: 'error',
          duration: 3000,
        })
      }else {
        this.newpasyy = true;
      }
    },
    changepasok(){
      if (this.oldpas === '' || this.newpas1 === '' || this.newpas2 === ''){
        ElNotification({
          title: '错误',
          message: '请将数据填写完整',
          type: 'error',
          duration: 3000,
        })
      }else {
        const pass = Encrypt(this.oldpas);
        const newpas = Encrypt(this.newpas2);
        this.axios.post('/userinfo/changepas', (response) => {
          if (response.obj){
            this.dialogFormVisible = false;
            this.$router.push({
              path: '/'
            })
            ElMessage.success({
              message: '修改成功，请重新登录！',
              type: 'success',
            });

          }
        },{
          oldpas: pass,
          newpas: newpas,
          userid: this.GET_USERID
        })
      }
    },
	  getCookie(name) {
		  let cookies = document.cookie.split('; ');
		  for (let i = 0; i < cookies.length; i++) {
			  let parts = cookies[i].split('=');
			  if (parts[0] === name) {
				  return parts[1];
			  }
		  }
		  return '';
	  }
  },
  mounted() {
	  document.querySelector('body').setAttribute('style', "background: url('https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/backimg.png') no-repeat center center fixed;-webkit-background-size: cover;-o-background-size: cover;background-size: cover;")
    //this.reload();
	  ElMessage.success('使用过程中有任何意见，都可点击右上角意见反馈进行反馈^_^')
    const that = this;
    this.axios.post("/userinfo/getuser", (response) => {
      //console.log(response)
      this.username = response.obj.name;
      this.userid = response.obj.id;
      this.SET_USER(this.username);
      this.SET_USERID(this.userid);
      this.SET_BDST(response.obj.bdst);
      this.SET_DEPART(response.obj.depart);
      this.SET_POSION(response.obj.posion);
      this.SET_DEPARTFLAG(response.obj.departflag);
	    let Token_cookie = this.getCookie('Token');
			console.log('Token_cookie->' + Token_cookie)
	    console.log(Token_cookie === '')
			if (Token_cookie === ''){
				this.axios.post('/userinfo/getTokenCra', (response) => {
					this.SET_TOKEN_CAR(response.obj)
					document.cookie = 'Token'+ "=" + response.message +";expires="+this.expires+";path=/"+";domain=.cr12cz.cn"
				},{
					user_id: this.GET_USERID
				})
			}
    })
    if (this.count > 0) {
      this.$notify({
        title: '警告',
        message: '这是一条警告的提示消息',
        type: 'warning',
        duration: 0
      });
    }
    this.menu = this.GET_MENU;
    this.timer = setInterval(() => {
      this.setNowTimes()
    }, 1000)

  }
}
</script>
<style>
#bc {
  width: 100%;
  height: 100%;
  top: 0; /*这里是设置与顶部的距离*/
  left: 0; /*这里是设置与左边的距离*/
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}

#logo {
  width: 50%;
  height: 50%;
  top: 30px; /*这里是设置与顶部的距离*/
  left: 80px; /*这里是设置与左边的距离*/
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;
  overflow: hidden;
}

#index_div {
  margin-top: 45px;
}

#font_div {
  color: white;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  height: 25px;
  line-height: 3px;
  width: 180px;
  text-align: center
}

#username_div {
  margin-left: 50px;
  font-family: '苹方', serif;
  color: white;
  font-weight: 500;
  font-size: 15px;
  margin-top: 2px
}

.el-button--gj {
  background: rgb(31, 90, 236);
  border-style: hidden;
  color: white;
}

.el-button--gj:hover {
  background: rgb(31, 103, 236);
  border-style: hidden;
  color: white;
}

.el-button--gj:active {
  background: rgb(31, 96, 236);
  border-style: hidden;
  color: white;
}

.el-button--gj:focus {
  background: rgb(31, 90, 236);
  border-style: hidden;
  color: white;
}

#mnue_div1 {
  position: absolute;
  top: 200px;
  left: 200px;
  background-color: white;
  width: 200px;
  min-height: 433px;
  border-radius: 10px;
  background-image: linear-gradient(white, #b5d6ff);
}

#mnue_div2 {
  position: absolute;
  top: 200px;
  left: 430px;
  background-color: white;
  width: 800px;
  min-height: 433px;
  border-radius: 10px;
  background-image: linear-gradient(white, #b5d6ff);
}
</style>
